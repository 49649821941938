import { RouteInfo } from "./sidebar.metadata";

export const ROUTES: RouteInfo[] = [
  {
    path: "",
    title: "external",
    icon: "",
    class: "nav-small-cap",
    label: "",
    labelClass: "",
    extralink: true,
    submenu: []
  },
  // Home
  {
    path: "/dashboard",
    title: "init",
    icon: "fa fa-th",
    class: "",
    label: "",
    labelClass: "label label-rouded label-themecolor pull-right",
    extralink: false,
    submenu: []
  },
  // Locale
  {
    path: "",
    title: "locals",
    icon: "mdi mdi-airplane-landing",
    class: "has-arrow",
    label: "",
    labelClass: "",
    extralink: false,
    submenu: [
      {
        path: "/dashboard/locales/country",
        title: "country",
        icon: "",
        class: "",
        label: "",
        labelClass: "",
        extralink: false,
        submenu: []
      },
      {
        path: "/dashboard/locales/city",
        title: "city",
        icon: "",
        class: "",
        label: "",
        labelClass: "",
        extralink: false,
        submenu: []
      },
      {
        path: "/dashboard/locales/airport",
        title: "airport",
        icon: "",
        class: "",
        label: "",
        labelClass: "",
        extralink: false,
        submenu: []
      }
    ]
  },
  // Company
  {
    path: "/dashboard/companies",
    title: "companies",
    icon: "mdi mdi-airplane-takeoff",
    class: "",
    label: "",
    labelClass: "",
    extralink: false,
    submenu: []
  },
  // Clientes
  {
    path: "/dashboard/clients",
    title: "clients",
    icon: "mdi mdi-account-multiple",
    class: "",
    label: "",
    labelClass: "label label-rouded label-themecolor pull-right",
    extralink: false,
    submenu: []
  },
  // Transfers
  {
    path: "/dashboard/transfers",
    title: "transfers",
    icon: "mdi mdi-ticket-confirmation",
    class: "",
    label: "",
    labelClass: "label label-rouded label-themecolor pull-right",
    extralink: false,
    submenu: []
  },
  // Maps
  {
    path: "/dashboard/map/users",
    title: "user_maps",
    icon: "mdi mdi-map",
    class: "",
    label: "",
    labelClass: "label label-rouded label-themecolor pull-right",
    extralink: false,
    submenu: []
  },
  {
    path: "",
    title: "internal",
    icon: "",
    class: "nav-small-cap",
    label: "",
    labelClass: "",
    extralink: true,
    submenu: []
  },
  // System
  {
    path: "",
    title: "system",
    icon: "mdi mdi-settings",
    class: "has-arrow",
    label: "",
    labelClass: "",
    extralink: false,
    submenu: [
      {
        path: "/dashboard/system/users_system",
        title: "user_system",
        icon: "",
        class: "",
        label: "",
        labelClass: "",
        extralink: false,
        submenu: []
      },
      {
        path: "/dashboard/system/user_profile",
        title: "profile",
        icon: "",
        class: "",
        label: "",
        labelClass: "",
        extralink: false,
        submenu: []
      }
    ]
  }
];

// Theme Config
import { theme_config } from '../../shared/models/theme-config';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// Language
import { TranslateService } from '@ngx-translate/core';
import * as language from '../../language_configure'

@Component({
  selector: 'app-main-layout',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  color = 'default';

  showMinisidebar = false;
  theme = theme_config;
  
  date = new Date();

  public config: PerfectScrollbarConfigInterface = {};

  constructor(
    public router: Router,
    public translate: TranslateService,
  ) {
    language.init(translate);
  }

  ngOnInit() {
    if (this.router.url === '/') {
      this.router.navigate(['/auth']);
    }
  }

  public updateDarkTheme(value: boolean): void {
    this.theme.showDarktheme = value;
  }
}
